<template>
  <v-row>
    <v-col class="d-flex flex-row items-center justify-start">
      <v-tabs
        v-model="tab"
        class="mb-10"
        height="40"
        slider-size="5"
        slider-color="primary"
        color="primary"
        id="tabs"
        @change="goToRoute()">
          <v-tab
            data-cy="tab-item"
            v-for="item in items"
            :key="item"
            class="no-uppercase text-subtitle-1 tab-items font-weight-medium"
            active-class="font-weight-bold primary--text">
            {{ item }}
          </v-tab>
      </v-tabs>
    </v-col>
    <v-col class="d-flex flex-row align-items-center justify-end">
      <v-btn
        v-show="displayCatalogBtn"
        color="primary"
        dark
        elevation="0"
        v-on="on"
        v-bind="{ attrs }"
        @click="downloadCatalog"
        :loading="isCatalogDownnloading"
        class="mt-2">
          <v-icon color="white" small>mdi-download</v-icon>
          <span class="body-1 white--text font-weight-medium text-capitalize">
            {{ $t('library.download_catalog') }}
          </span>
        </v-btn>
        <v-btn
          v-show="displayDownloadReportBtn"
          color="primary"
          dark
          elevation="0"
          v-on="on"
          v-bind="{ attrs }"
          @click="downloadPerformaceByAssigmentReport"
          :loading="isReportDownloading"
          class="mt-2">
            <v-icon color="white" small>mdi-download</v-icon>
            <span class="body-1 white--text font-weight-medium text-capitalize">
              {{ $t('actions.export') }}
            </span>
        </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'
import * as Sentry from '@sentry/vue'

import courseBook from '../../services/courseBook'

export default {
  name: 'TabSelector',
  data() {
    return {
      tab: 0,
      isCatalogDownnloading: false,
      displayCatalogBtn: true,
      isReportDownloading: false,
      displayDownloadReportBtn: false
    }
  },
  computed: {
    items() {
      return [
        this.$t('library.library'),
        this.$t('library.book_assignment'),
      ]
    },
  },
  mounted() {
    this.cleanTabBackgroundColor()
    this.checkViewToDisplayActions()
    this.checkRoute()
  },
  methods: {
    ...mapActions('reports', ['getPerformanceByAssigmentExcelFile']),
    cleanTabBackgroundColor() {
      const elementActiveUsers = document.getElementById('tabs')
      elementActiveUsers.classList.remove('theme--light')
    },
    goToRoute() {
      if (this.tab === 0) {
        this.$router.push({ name: 'Books' })
      } else {
        this.$router.push({ name: 'Assignments' })
      }
    },
    checkViewToDisplayActions() {
      const currentView = this.$router.history.current.name
      this.displayCatalogBtn = currentView === 'Books'
      this.displayDownloadReportBtn = currentView !== 'Books' && currentView !== 'Assignments'
    },
    checkRoute() {
      this.tab = this.$router.history.current.name === 'Books' ? 0 : 1
    },
    async downloadCatalog() {
      try {
        this.isCatalogDownnloading = true
        const response = await fetch('https://s3.us-east-2.amazonaws.com/beereaders.com/marketing/catalogs/beereaders-catalogo-2024-02.pdf')
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        try {
          const blob = await response.blob()
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'beereaders_catalogo.pdf'
          link.click()
        } catch (error) {
          Sentry.captureException(error)
          this.$modal.error(error)
        }
      } catch (error) {
        Sentry.captureException(error)
        this.$modal.loadError()
      } finally {
        this.isCatalogDownnloading = false
      }
    },
    async downloadPerformaceByAssigmentReport() {
      try {
        this.isReportDownloading = true

        const { assigmentId } = this.$router.history.current.params
        const courseBookResponse = await courseBook
            .getByCourseBookId(assigmentId)
            .catch((e) => console.error(e))

        const { course, evaluationDate, startingDate } = courseBookResponse.content[0]
        const payload = {
          courseId: course.id, startingDate, evaluationDate, assigmentId
        }

       // eslint-disable-next-line max-len
        const res = await this.getPerformanceByAssigmentExcelFile(payload)
        const outputFilename = 'Estadística del libro.xlsx'

        const url = URL.createObjectURL(new Blob([res]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', outputFilename)
        document.body.appendChild(link)

        link.click()
      } catch (error) {
        Sentry.captureException(error)
        this.$modal.loadError()
      } finally {
        this.isReportDownloading = false
      }
    },
  },
  watch: {
    $route() {
      this.checkRoute()
      this.checkViewToDisplayActions()
    }
  }
}
</script>

<style scoped>
.no-uppercase {
  text-transform: none;
}
.v-tabs-bar {
  height: 80px !important;
}
.v-tabs-bar__content {
  height: 80px !important;
}
.v-tabs-slider-wrapper {
  height: 5px;
}
.tab-items {
  color: rgba(0, 0, 0, 0.3);
  border-bottom: 5px solid rgba(0, 0, 0, 0.3);
  margin-left: 10px;
  margin-right: 10px;
}
</style>
