<template>
    <div class="w-100">
        <vue-headful :title="$t('title_library')" description="title" />
        <TabSelector class="mt-6" style="margin-left: 10px;" />
        <router-view />
    </div>
</template>

<script>
import TabSelector from '@/components/library/TabSelector.vue'

export default {
    name: 'Library',
    components: {
        TabSelector,
    },
}
</script>
